import styled from 'styled-components';
import { BREAKPOINT, PADDING } from '@latitude/core/utils/constants';
import CardTile from './_CardTile';

export const StyledGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: ${BREAKPOINT.MD}) {
    justify-content: space-between;
  }
`;

export const StyledGroupItem = styled.div`
  width: 100%;
  max-width: 350px;

  @media (min-width: ${BREAKPOINT.MD}) {
    flex: 0 0 calc(50% - 15px);
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    flex: 0 0 calc(33.333333% - 15px);
  }
`;

export const StyledCardTile = styled(CardTile)`
  width: 100%;
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;

  sup {
    font-size: 0.6em;
    top: -0.6em;
  }
`;

export const StyledCardTileContent = styled.div`
  padding: ${PADDING.P24};
  text-align: center;
  a {
    display: inline-block;
  }
`;
